export const USER_LISTING = 'USER_LISTING'
export const USER_LIST_FILTERS = 'USER_LIST_FILTERS'
export const CREATEUSER = 'CREATEUSER'
export const CHECKUSEREXISTS = 'CREATEUSEREXISTS'
export const USER_FORM_DATA = 'USER_FORM_DATA'
export const USER_DETAILS = 'USER_DETAILS'
export const GETHOTELROLE = 'GETHOTELROLE'
export const USER_SET_STATE = 'USER_SET_STATE'
export const USER_DETAIL_SET_STATE = 'USER_DETAIL_SET_STATE'
export const GET_UKG_CREDENTIALS = 'GET_UKG_CREDENTIALS'
export const CLEAR_DETAIL_DATA = 'CLEAR_DETAIL_DATA'
